import { createStore } from 'vuex'

const axios = require('axios').default;
const store = createStore({
  state: {
    product: {},
    loadingProduct: {}
  },
  getters: {
    product(state) {
      return function (link) {
        console.log(link);
        if (state.product[link]) {
          return state.product[link];
        } else {
          if (!state.loadingProduct[link]) {
            state.loadingProduct[link] = true;
            store.commit("loadProduct", link);
          }
          return null;
        }
      }
    }
  },
  mutations: {
    async loadProduct(state, link) {
      if (link.substring(link.length - 1) == "/") {
        link = link.substring(0, link.length - 1);
      }
      try {
        let res = await axios.get("/api" + link + ".json");
        if (res.data) {
          state.loadingProduct[link] = false;
          state.product[link] = res.data;
        }
      } catch {
        console;
      }
    }
  },
  actions: {
  },
  modules: {
  }
})

export default store;